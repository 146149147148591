import React, { useEffect } from 'react'
import BasePage from '../../components/basePage'
import { Flex, Text, chakra } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import { getEmail } from '../../helpers/database'

const VerifyEmailPage = () => {
	const email = getEmail()

	// redirect user to login if no email
	useEffect(() => {
		if (!email) {
			return navigate('/login')
		}
	}, [])

	return (
		<BasePage
			access={0}
			theme="light"
			parent="login"
			backBtn="/"
			largeTitle={
				<>
					Verification
					<br />
					Email SENT
				</>
			}
		>
			<Flex
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
			>
				<StaticImage
					alt="Target"
					width={215}
					style={{ marginTop: '60px' }}
					src="../../images/target.png"
				/>
				<Text variant="ml" textAlign="center" mt="80px">
					To continue, open the sign in link we’ve sent to{' '}
					<chakra.span fontWeight="700" fontSize="18px" color="black">
						{email || ''}
					</chakra.span>
				</Text>
			</Flex>
		</BasePage>
	)
}

export default VerifyEmailPage
