import * as React from 'react'
import Layout from '../../layout'
import VerifyEmailPage from '../../pagesComponents/login/verify-email'

const VerifyEmail = () => {
	return (
		<Layout theme="light" title="Verify Email">
			<VerifyEmailPage />
		</Layout>
	)
}

export default VerifyEmail
